import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { RequestTokenService } from "../request-token.service";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { RegisterAccess } from "../../app/shared/Entities/RegisterAccess";

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})

export class AnalistaService {

  constructor(private http: HttpClient,
    private requestToken: RequestTokenService
  ) {}

  private handleError(error: any): Observable<never> {
    console.error('An error occurred', error);
    return throwError(error);
  }
  
  public GetAnalista(): Observable<any>{
    const options = {
      headers: this.requestToken.createAuthorizationHeader(),
      withCredentials: true // Garante que cookies/autenticação sejam enviados
    };

    return this.http.get<any>(`${API_URL}v1/Analista/GetAnalista`, options)
    .pipe(
      catchError(this.handleError)
    )
  }

  public RegistraAcesso(signature: RegisterAccess): Observable<any>{
    const options = {
      headers: this.requestToken.createAuthorizationHeader(),
      withCredentials: true // Garante que cookies/autenticação sejam enviados
    };

    return this.http.post<any>(`${API_URL}v1/Analista/RegistraAcesso`, signature, options)
    .pipe(
      catchError(this.handleError)
    )
  }
}
